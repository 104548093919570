import AuthRequest from "@/models/Requests/AuthRequest";
import AuthResponse from "@/models/Responses/AuthResponse";
import Axios, { AxiosError, AxiosResponse } from "axios";

export default abstract class AuthService {
  private static axios = Axios.create({
    baseURL: process.env.VUE_APP_API_URL + "/",
    withCredentials: true,
  });

  public static async getCSRF(): Promise<void> {
    await this.axios.get("/sanctum/csrf-cookie");
  }

  public static async login(auth: AuthRequest): Promise<AuthResponse> {
    try {
      await this.getCSRF();
      const response = await this.axios.post("/login", auth);
      return { success: true, two_factor: response.data.two_factor };
    } catch (error: unknown) {
      if ((error as AxiosError).response?.status === 422) {
        throw {
          success: false,
          message: "Gebruikersnaam en/of wachtwoord onjuist",
        } as AuthResponse;
      }
      throw {
        success: false,
        message: "Onbekende fout.",
      } as AuthResponse;
    }
  }

  public static logout(): Promise<AxiosResponse<any, any>> {
    return this.axios.post("/logout");
  }

  public static async getAuthUser(): Promise<AxiosResponse["data"]> {
    const response = await this.axios.get("/user");
    return response.data;
  }
}
