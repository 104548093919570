import { RootState } from "@/store/types";
import { NavigationGuardNext, Route } from "vue-router";
import { Store } from "vuex/types/index";

type Context = {
  to: Route;
  from: Route;
  next: NavigationGuardNext<Vue>;
  store: Store<RootState>;
};

export default function middlewarePipeline(
  context: Context,
  middleware: any,
  index: number
): NavigationGuardNext<Vue> {
  const nextMiddleware = middleware[index];
  if (!nextMiddleware) {
    return context.next;
  }
  return () => {
    nextMiddleware({
      ...context,
      next: middlewarePipeline(context, middleware, index + 1),
    });
  };
}
