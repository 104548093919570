import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        accent: "#3c3c3c",
        primary: "#81bd06",
        secondary: "#3d214f",
        background: "#fefedc",
      },
      dark: {
        background: "#2e2e2e",
        primary: "#70a505",
        secondary: "#b2a7b8",
      },
    },
  },
});
