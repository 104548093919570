export default function auth({
  to,
  next,
  store,
}: {
  to: any;
  next: any;
  store: any;
}): void {
  const loginQuery = { name: "Login", query: { redirect: to.fullPath } };
  console.log("Auth middleware");
  if (!store.getters["auth/authUser"]) {
    store.dispatch("auth/getAuthUser").then(() => {
      if (!store.getters["auth/authUser"]) next(loginQuery);
      else next();
    });
  } else {
    next();
  }
}
