import router from "@/router";
import AuthService from "@/services/AuthService";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex/types/index";
import { RootState } from "../types";
import State from "./types";

const state: State = {
  authenticated: false,
  authUser: null,
};
const mutations: MutationTree<State> = {
  SET_USER(state, value: State["authUser"]) {
    state.authUser = value;
  },

  SET_AUTHENTICATED(state, value: boolean) {
    state.authenticated = value;
  },
};

const actions: ActionTree<State, RootState> = {
  async getAuthUser({ commit }) {
    try {
      const authUser = await AuthService.getAuthUser();
      commit("SET_USER", authUser);
      commit("SET_AUTHENTICATED", true);
      return authUser;
    } catch (error) {
      commit("SET_USER", null);
      commit("SET_AUTHENTICATED", false);
    }
  },
  logout({ commit }) {
    return AuthService.logout().then(() => {
      commit("SET_USER", null);
      commit("SET_AUTHENTICATED", false);
      if (router.currentRoute.name !== "login") {
        router.push({ name: "Login" });
        this.$toast.success("Je bent succesvol uitgelogd!");
      }
    });
  },
};

const getters: GetterTree<State, RootState> = {
  authenticated(state) {
    return state.authenticated;
  },
  authUser(state) {
    return state.authUser;
  },
};

export const auth: Module<State, RootState> = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
