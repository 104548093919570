import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import axios, { AxiosError } from "axios";

Vue.config.productionTip = false;

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error: AxiosError) {
    if (error.response) {
      switch (error.response.status) {
        case 401: // Not logged in
        case 419: // Session expired
        case 503: // Down for maintenance
          // Bounce the user to the login screen with a redirect back
          Vue.$toast.error("Sessie verlopen! Log opnieuw in.");
          window.location.reload();
          break;
        default:
          return Promise.reject(error);
      }
    }
  }
);

Vue.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true,
});

// store.dispatch("auth/getAuthUser").then(() => {
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
// });
