import auth from "@/middleware/auth";
import guest from "@/middleware/guest";
import store from "@/store";
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import middlewarePipeline from "./middlewarePipeline";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "MainShell",
    component: () =>
      import(/* webpackChunkName: "mainshell" */ "@/views/MainShell.vue"),
    children: [
      {
        path: "",
        name: "HomeRoute",
        meta: { middleware: [auth] },
        component: () =>
          import(/* webpackChunkName: "home" */ "@/views/Home.vue"),
      },
      {
        path: "/objecten",
        name: "ObjectListView",
        meta: { middleware: [auth] },
        component: () =>
          import(
            /* webpackChunkName: "objectroute" */ "@/views/objecten/ObjectListView.vue"
          ),
      },

      {
        path: "/objecten/:slug",
        name: "ObjectEditView",
        meta: { middleware: [auth] },
        component: () =>
          import(
            /* webpackChunkName: "objectroute" */ "@/views/objecten/ObjectEditView.vue"
          ),
      },
    ],
  },
  {
    path: "/auth",
    name: "AuthShell",
    meta: { middleware: [guest] },
    component: () =>
      import(/* webpackChunkName: "authshell" */ "@/views/auth/AuthShell.vue"),
    children: [
      {
        path: "login",
        name: "Login",
        meta: { middleware: [guest] },
        component: () =>
          import(/* webpackChunkName: "auth-login" */ "@/views/auth/Login.vue"),
      },
      {
        path: "forgotpassword",
        name: "ForgotPassword",
        meta: { middleware: [guest] },
        component: () =>
          import(
            /* webpackChunkName: "auth-forgot" */ "@/views/auth/Forgot.vue"
          ),
      },
      {
        path: "resetpassword",
        name: "ResetPassword",
        meta: { middleware: [guest] },
        component: () =>
          import(
            /* webpackChunkName: "auth-forgot" */ "@/views/auth/Reset.vue"
          ),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const middleware = to.meta?.middleware;
  const context = { to, from, next, store };

  if (!middleware) {
    return next();
  }
  middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  });
});

export default router;
