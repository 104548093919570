



import { Component, Vue } from "vue-property-decorator";

@Component
export default class App extends Vue {
  beforeCreate(): void {
    this.$vuetify.theme.dark = localStorage.getItem("darkMode") === "true";
  }
}
